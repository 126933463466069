.app .stats{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 35px;
}

/* Shrink grid to one column for smaller screens */
@media (max-width: 768px) {
    .app .stats {
        grid-template-columns: 1fr;
    }
}